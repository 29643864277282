@tailwind base;
@tailwind components;
@tailwind utilities;
@import "toastr";

.ant-spin-dot-item {
    background-color: green !important;
}

/* .ant-table-thead .ant-table-cell{
    background-color: #87b394 !important;
  }
  .ant-table-footer{
    background-color: #87b394 !important;
  } */

.ant-btn-primary {
    background: rgb(95, 95, 193) !important;
}

img {
    display: initial !important;
}

@media print {
    @page {
        size: A4 portrait;
        margin: 0;
    }

    body {
        margin: 10mm;
        padding: 0;
        -webkit-print-color-adjust: exact !important;
        print-color-adjust: exact !important;
    }

    .react-grid-layout {
        display: block !important;
        width: 100% !important;
        height: auto !important;
        transform: scale(0.7, 0.9) !important;
        transform-origin: top left; /* Align scaling */
    }

    .react-grid-item {
        page-break-inside: avoid !important;
        break-inside: avoid !important;
        overflow: visible !important;
    }
}
